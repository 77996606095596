.table{
    border-collapse: collapse;
}

.square{
    width: var(--tile-max-size);
    height: var(--tile-max-size);
    background-color: white;;
    border: solid 1px grey;
    font-size: 2rem;
    padding: 0;

    &.blocked{
        background-color: black;
        pointer-events: none;
        cursor: not-allowed;
    }

    button{
        display: block;
        width: 100%;
        height: 100%;
    }
}
